@import "../../common/style/mixin";
@import "../../common/style/animation";
@import "../../common/style/variables";

.navigation-wrapper {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100vw;

  .overlay {
    position: absolute;
    background: #ffffff69;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: -1;
  }

  nav {
    @include flexCenter(flex-start, center);
    background: $white;
    transition-duration: $time;
    transition-timing-function: $way;
    gap: 25px;
    padding: 12px;

    &.close {
      transition-property: background, box-shadow;
      background: #ffffff00;
      box-shadow: #32325d40 0px 6px 12px -2px, #0000004d 0px 3px 7px -3px;
    }

    &.open {
      transition-property: box-shadow;
      box-shadow: none;
    }

    .logo {
      width: 120px;
    }

    .nav-icon-collapse {
      background: transparent;
      cursor: pointer;

      svg {
        width: 30px;
      }

      .top-line.open {
        animation: topLineForth $time $way forwards;
      }
      .middle-line.open {
        animation: middleLineForth $time $way forwards;
      }
      .bottom-line.open {
        animation: bottomLineForth $time $way forwards;
      }

      .top-line.close {
        animation: topLineBack $time $way forwards;
      }
      .middle-line.close {
        animation: middleLineBack $time $way forwards;
      }
      .bottom-line.close {
        animation: bottomLineBack $time $way forwards;
      }
    }
  }

  .active-nav-items {
    transition-property: height, background;
    transition-duration: $time;
    transition-timing-function: $way;
    height: 100vh;
    background: $white;
    @include flexCenter(flex-start, stretch);
    flex-direction: column;
    gap: 20px;

    .item {
      @include flexCenter(space-around, center);
      font-size: 1.25rem;
      animation: show $time $way forwards;
      transform: translate(0px, 40px);
      cursor: pointer;

      p {
        width: 220px;
        font-weight: bold;
      }

      .right-arrow {
        font-size: 25px;

        &.hide-arrow {
          visibility: hidden;
        }
      }
    }

    .project-tab-wrapper {
      width: 310px;
      margin: 0 auto;
      animation: show $time $way forwards;
      transform: translate(0px, 40px);

      .project-tab-header {
        cursor: pointer;
        .left-arrow {
          font-size: 1.5rem;
          position: relative;
          transform: translateX(-15px);
          width: 45px;
        }
        p:last-child {
          transform: translateX(-15px);
          font-size: 1.19rem;
        }

        @include flexCenter(flex-start, center);
        margin-bottom: 20px;
      }

      .tab-name {
        font-weight: bold;
        font-size: 1.44rem;
        margin-bottom: 20px;
      }

      .tab-list-wrapper {
        @include flexCenter(center, normal);
        flex-direction: column;
        gap: 15px;
      }
    }
  }

  .hidden-nav-items {
    transition-property: height, background;
    transition-duration: $time;
    transition-timing-function: $way;

    height: 0vh;
    backdrop-filter: blur(5px);
  }
}

@media only screen and (min-width: 699px) {
  .large-screen {
    nav {
      @include flexCenter(space-between, center);
      padding: 12px 30px;
      background: $white;
      transition-duration: 0.3s;
      transition-timing-function: $way;
    }
    .project-button {
      border: none;
      background: transparent;
      outline: none;
      box-shadow: none;
      padding: auto;
      @include flexCenter(center, center);
    }

    .action-burtons {
      @include flexCenter(end, unset);
      gap: 15px;

      .nav-dropdown {
        padding: 0px;
        gap: 8px;
        svg {
          transition: transform 0.4s;
          transform: rotate(90deg);
        }
        &:hover {
          svg {
            transform: rotate(-90deg);
            transition: transform 0.4s;
          }
        }
      }

      .nav-item {
        font-size: 1.25rem;
        font-weight: 400 !important;
        background: transparent;
      }
    }
  }

  .ant-dropdown {
    min-width: 150px;
    .ant-dropdown-menu {
      background: #fcfcfc;
      background: radial-gradient(circle, #fcfcfc 0%, #eaeaea 100%, rgba(0, 0, 0, 1) 100%) !important;

      .ant-dropdown-menu-item {
        &:hover {
          transition-property: transform, background, text-decoration, font-weight;
          transition-duration: 0.1s;
          transition-timing-function: $way;
          background: none;
          text-decoration: underline;
          font-weight: bold;
          transform: scale(1.02);
        }
      }
    }
  }
}

.get-in {
  animation: show $time $way forwards;
}
