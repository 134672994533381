@import "../../common/style/mixin";
$width: 412px;

.hero-wrapper {
  position: relative;

  .hero-wrapper-right-side {
    position: relative;
    min-height: 540px;
    margin-top: 100px;
    .shadow-wrapper {
      box-shadow: #63636382 0px 2px 8px 0px;
      width: $width;
      position: absolute;
      inset: -25px -215px auto auto;
      border-radius: 27px;
      padding: 20px;
      transform: rotate(31deg);
      height: 560px;
      z-index: -1;

      .over {
        height: 100%;
        width: $width;
        position: absolute;
        inset: 0px 0px auto auto;
        border-radius: 27px;
        padding: 20px;
        transform: rotate(360deg);
        background: #ffffff00;
        backdrop-filter: blur(5px);
      }

      .maor-image {
        width: 800px;
        transform: rotate(330deg);
        position: absolute;
        inset: -26px 0 auto -304px;
      }
    }

    img {
      position: absolute;
    }

    .ball-shadow {
      z-index: -2;
      right: 210px;
      width: 100px;
      top: 225px;
    }

    .figma {
      right: 278px;
      top: 5px;
      z-index: -1;
      animation: fly 3s linear infinite alternate;
    }
    .xd {
      right: 208px;
      top: 105px;
      z-index: -1;
      animation: fly 3s linear 1s infinite alternate;
    }
    .css {
      right: 170px;
      top: 20px;
      z-index: -1;
      animation: fly 3s linear 2s infinite alternate;
    }
    .ai {
      right: 39px;
      top: 1px;
      z-index: -1;
      animation: fly 3s linear 1.5s infinite alternate;
    }
    .ps {
      right: 0;
      top: 119px;
      z-index: -1;
      animation: fly 3s linear 2s infinite alternate;
    }
  }

  .about {
    padding: 0 20px;
    max-width: 450px;
    .about-header {
      font-size: 3rem;
    }
    .about-div {
      @include flexCenter(flex-start, center);

      :first-child {
        font-size: 4.5rem;
        -webkit-text-stroke: 2px #000000;
        -webkit-text-fill-color: transparent;
      }
      img {
        width: 90px;
      }
    }
    .about-description {
      line-height: 1.5;
      margin-bottom: 10px;
    }
  }
}

@media only screen and (min-width: 750px) {
  .hero-wrapper {
    @include flexCenter(space-around, center);
    flex-direction: row-reverse;
    gap: 250px;
  }
}

@media only screen and (min-width: 1000px) {
  $width: 904px;
  .hero-wrapper {
    .hero-wrapper-right-side {
      margin-top: 150px;
      .shadow-wrapper {
        inset: -62px -685px auto auto;
        height: 740px;
        width: $width;

        .over {
          width: $width;
        }
        .maor-image {
          width: 1110px;
          inset: -71px 0 auto -430px;
        }
      }

      .ball-shadow {
        z-index: -2;
        right: 210px;
        width: 150px;
        top: 180px;
      }
      .figma {
        right: 310px;
        top: -25px;
        z-index: -1;
      }
      .xd {
        right: 210px;
        top: 80px;
        z-index: -1;
      }
      .css {
        right: 180px;
        top: -51px;
        z-index: -1;
      }
      .ai {
        right: -49px;
        top: -55px;
        z-index: -1;
      }
      .ps {
        right: -82px;
        top: 80px;
        z-index: -1;
      }
    }
  }
}

@keyframes fly {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(20px);
  }
}
