@import "../../common/style/mixin";
.about-wrapper {
  width: 90%;
  margin: 150px auto;
  max-width: 1000px;

  .cv-image {
    display: none;
  }

  .about-title {
    font-size: 2rem;
    font-weight: 100;
    margin-bottom: 30px;
  }

  .about-content-wrapper {
    .about-content-description {
      .about-description {
        margin-bottom: 20px;
        line-height: 1.5;
      }
      .cv-link {
        background: transparent linear-gradient(248deg, #8c9eff 0%, #4d82cb 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        transform: skewX(-12deg);
        font-weight: 100;
        text-decoration: none;
        padding: 13px;
        @include flexCenter(center, center);
        font-size: 1.2rem !important;
        color: white;
        width: max-content;
        border-radius: 6px;
        border: 1px solid white;
      }
    }

    .cv-image {
      width: 400px;
    }
  }
}

@media only screen and (min-width: 700px) {
  .about-content-wrapper {
    .cv-image {
      display: inline-block;
    }
    @include flexCenter(space-between, center);
    .about-content-description {
      .about-description {
        margin-bottom: 10px;
        max-width: 500px;
      }
    }
  }
}
