@import "../../../common/style/mixin";

.tab-list-item {
  @include flexCenter(space-between, center);
  cursor: pointer;
  p,
  svg {
    color: #757575;
  }
  svg {
    font-size: 1.88rem;
  }
}
