@import "../../style/mixin";

.lets-talk-button {
  background: transparent linear-gradient(248deg, #8c9eff 0%, #4d82cb 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  transform: skewX(-12deg);
  font-weight: 300;
  padding: 20px;
  @include flexCenter(center, center);
  font-size: 1rem !important;
  width: 100px;
  margin: 0 auto 0 0;
  border-radius: 6px;
}
