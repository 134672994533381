@import "../../style/mixin";

.contact-wrapper {
  width: 90%;
  margin: 150px auto 0 auto;
  max-width: 1000px;

  .contact-info {
    .contact-title {
      font-size: 2rem;
      font-weight: 100;
      margin-bottom: 30px;
    }

    .op {
      @include flexCenter(flex-start, flex-start);
      flex-direction: column;
      gap: 20px;
    }
    .info-item {
      @include flexCenter(flex-start, center);
      gap: 15px;
      text-decoration: none;
      color: #2b3b56;

      .image-wrapper {
        background: #2b3b56;
        padding: 10px;
        border-radius: 50%;
        @include flexCenter(center, center);
        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
      }
    }
  }

  .looshes {
    @include flexCenter(center, center);
    flex-direction: column;
    gap: 8px;
    margin-top: 50px;
    text-decoration: none;
    color: #2b3b56;
    width: max-content;
    margin: 50px auto;
    padding-bottom: 10px;

    img {
      width: 150px;
    }
  }
}
