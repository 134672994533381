.project-wrapper {
  display: flex;
  justify-content: center;
  margin: 150px auto;
  flex-direction: column;
  max-width: 1000px;
  width: 90%;
  position: relative;

  .project-title {
    font-size: 2rem;
    font-weight: 100 !important;
    margin-bottom: 30px;
    text-align: center;
  }
  .project-wrapper-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
  }

  .ball {
    position: absolute;
    z-index: -2;
  }

  .first-ball {
    width: 200px;
    top: 325px;
    right: -112px;
  }

  .second-ball {
    width: 200px;
    top: 485px;
    left: -117px;
  }

  .third-ball {
    width: 200px;
    top: 950px;
    right: -98px;
  }
}

@media only screen and (min-width: 750px) {
  .project-wrapper {
    .project-title {
      text-align: left;
    }
  }
}
