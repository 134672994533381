@import "../../style/variables";
@import "../../style/mixin";

.card-wrapper {
  background: #fcfcfc;
  background: linear-gradient(309deg, #fcfcfc 0%, #cfbbff80 0%, #c9cbff80 100%);
  position: relative;
  padding: 20px;
  @include flexCenter(space-evenly, center);
  flex-direction: column-reverse;
  margin: 0 auto;
  border-radius: 15px;
  min-height: 340px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: background-image 0.5s linear;
  cursor: pointer;

  .card-overlay {
    position: absolute;
    background: #ffffff00;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: -1;
    border-radius: 15px;
  }

  .left-side {
    @include flexCenter(center, center);
    flex-direction: column;
    gap: 20px;

    .card-text {
      max-width: 450px;
      line-height: 1.5;
      text-align: center;
    }
    button {
      box-shadow: 0px 3px 6px #00000029;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      transform: skewX(-12deg);
      font-weight: 600;
      font-size: 1.25rem;
      padding: 10px;
      border-radius: 6px;
      .card-link {
        background: -webkit-linear-gradient(#cfbbff, #c9cbff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-decoration: none;
      }
    }
  }

  .card-image {
    max-width: 600px;
    width: 100%;
  }

  .influencer {
    margin-left: 45px;
  }

  .wishful,
  .payment {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 750px) {
  .card-wrapper::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, #fcfcfc 0%, #11023680 0%, #11023680 0%, #f0e8fd 100%);
    z-index: -1;
    transition: opacity $time $way;
    opacity: 0;
    border-radius: 15px;
  }

  .card-wrapper:hover::before {
    opacity: 1;
    background: linear-gradient(309deg, #fcfcfc 0%, #cfbbff80 0%, #00000083 100%);
    .left-side {
      .card-text {
        color: white !important;
      }
    }
  }

  .card-wrapper {
    flex-direction: row;
    margin: 0;
    transition: $time $way;
    &:hover {
      color: white !important;
      transition: color $time $way;
    }

    .left-side {
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;

      button {
        .card-link {
          background: linear-gradient(#9a8cbb, #abacd3);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-decoration: none;
        }
      }

      .card-text {
        text-align: left;
      }
    }
    .card-image {
      width: 430px;
    }

    .influencer {
      margin-left: 0px;
      position: relative;
    }
  }
}
