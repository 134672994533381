/* TEXT */
@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Action Start */
@keyframes topLineForth {
  from {
    transform: rotate(0deg);
    transform-origin: top right;
  }

  to {
    transform: rotate(-50deg);
    transform-origin: 26px 6px;
  }
}

@keyframes middleLineForth {
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }

  to {
    transform: rotate(50deg);
    transform-origin: center;
  }
}

@keyframes bottomLineForth {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-50deg) translate(-14px, -1.5px);
    transform-origin: top left;
  }
}

/* Action End */
@keyframes topLineBack {
  from {
    transform: rotate(-50deg);
    transform-origin: 26px 6px;
  }

  to {
    transform: rotate(0deg);
    transform-origin: right;
  }
}

@keyframes middleLineBack {
  from {
    transform: rotate(50deg);
    transform-origin: center;
  }

  to {
    transform: rotate(0deg);
    transform-origin: center;
  }
}

@keyframes bottomLineBack {
  from {
    transform: rotate(-50deg) translate(-14px, -1.5px);
    transform-origin: top left;
  }

  to {
    transform: rotate(0deg);
  }
}
